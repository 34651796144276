import React, { useState } from "react";
import Favicon_NoBg from "../assets/Images/Favicon_NoBg.svg";
import "../styles/style.css";
import {
  Divider,
  Button,
  Hidden,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Grid,
} from "@mui/material";
import CloseImage from "../assets/Images/closeCircle.png";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

const Header = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <div className="header_container">
      <div className="header">
        <div className="headercontain">
          <img src={Favicon_NoBg} alt="SVGIcon" className="logo" />
          <Hidden smUp>
            <MenuRoundedIcon
              className="menuCustom"
              onClick={toggleDrawer(true)}
            />
          </Hidden>
          <div className="center_align">
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className="divider_std"
            />
          </div>
          <Hidden smDown>
            <div className="navigation">
              <ul>
                <li
                  className="listbutton"
                  onClick={() => props.scrollToSection("productSection")}
                >
                  PRODUCT
                </li>
                <li
                  className="listbutton"
                  onClick={() => props.scrollToSection("serviceSection")}
                >
                  SERVICES
                </li>
                <li
                  className="listbutton"
                  onClick={() => props.scrollToSection("aboutSection")}
                >
                  ABOUT
                </li>
              </ul>
            </div>
          </Hidden>

          <Button className="ordernow" onClick={() => props.handleOpenModal()}>
            ORDER NOW
          </Button>
        </div>
      </div>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{ sx: { width: "100%" } }}
      >
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          className="drawerContainer"
        >
          <Grid
            container
            alignContent={"center"}
            style={{ padding: "20px 20px" }}
          >
            <Grid item xs={6} container alignContent={"center"}>
              <img src={Favicon_NoBg} alt="SVGIcon" className="logo" />
            </Grid>
            <Grid item xs={6} container justifyContent={"end"}>
              <IconButton onClick={toggleDrawer(false)}>
                <img src={CloseImage} width={35} alt="close" />
              </IconButton>
            </Grid>
            <Grid item xs={12} style={{padding:"50px 0px 0px 0px"}}>
              <List>
                <ListItem
                  button
                  onClick={() => props.scrollToSection("productSection")}
                >
                  <ListItemText  className="listbutton2" primary="PRODUCT" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => props.scrollToSection("serviceSection")}
                >
                  <ListItemText className="listbutton2" primary="SERVICES" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => props.scrollToSection("aboutSection")}
                >
                  <ListItemText className="listbutton2" primary="ABOUT" />
                </ListItem>
              
              </List>
            </Grid>
          </Grid>
          <Grid style={{padding:"10px"}}>
          <Button className="ordernow21" onClick={() => props.handleOpenModal()}>
            ORDER NOW
          </Button>
          </Grid>
         
        </div>
      </Drawer>
    </div>
  );
};

export default Header;
