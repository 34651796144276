import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Hidden,
} from "@mui/material";
import "../styles/style.css";
import closeImage from "../assets/Images/closeCircle.png";
import ThatIs from "../assets/Images/thatIs.png";
import Frame1 from "../assets/Images/Frame1.png";
import Frame2 from "../assets/Images/Frame2.png";
import Frame3 from "../assets/Images/Frame3.png";
import RightArrow from "../assets/Images/rightArrowCircle.png";
import LeftArrow from "../assets/Images/leftArrowCircle.png";
import closeImageNo from "../assets/Images/close.png";

const CustomRoad = ({ open, onClose, handleOpenModal }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleNextImage = () => {
    setImageIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
  };

  const handlePrevImage = () => {
    setImageIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));
  };

  const handleTooltipToggle = () => {
    setTooltipOpen((prev) => !prev);
  };

  const tooltipContent = (
    <div>
      <Typography className="subtitleModel6">
        <strong>HandleBracks®</strong> a registered trademark of Toughscreen
        Ltd are unique brackets that fit directly around handles and securely
        attach to existing fixing points whilst remaining full accessibility and
        use of the handle itself.
      </Typography>
      <Hidden lgUp>
        <IconButton
          onClick={handleTooltipToggle}
          sx={{ position: "absolute", top: 0, right: 0 }}
        >
          <img src={closeImageNo} width={14} alt="close" />
        </IconButton>
      </Hidden>
    </div>
  );

  return (
    <Modal open={open} className="model" aria-labelledby="modelSet">
      <div>
        <Hidden lgDown>
          <Grid container className="modelContainerCustom">
            <Box className="customBox2">
              <Typography
                variant="body1"
                gutterBottom
                className="subtitleModel2"
              >
                Safety made easy with HandleBracks®{" "}
                <Tooltip
                  className="customToolTip"
                  style={{ background: "#fff" }}
                  title={tooltipContent}
                >
                  <img
                    src={ThatIs}
                    alt="thatIsSymbol"
                    width={18}
                    style={{
                      marginLeft: "4px",
                      paddingTop: "3px",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                className="titleModel2"
              >
                Ford Tourneo Custom
              </Typography>

              <Typography
                variant="body1"
                gutterBottom
                className="subtitleModel70"
              >
                Introducing the Ford Tourneo Custom 2012 - present Top Handle
                1/2 Driver Protection Shield – a sleek solution crafted from 2mm
                steel HandleBracks® and 10mm polycarbonate. Simply unclip
                existing bolt cover trims, refit longer bolts and install within
                minutes. Designed for rear-facing seats, it offers easy
                self-installation and an OEM look. Compliant with UN/ECE
                Regulation No. 43, it ensures top-notch safety. PCT Patent Pending application number: PCT/GB2024/051803
              </Typography>

              <Box component="div" marginTop={2} className="subtitleModel">
                <ul>
                  <li>
                    Robust 2mm steel HandleBracks® with durable powder coating
                  </li>
                  <li>10mm polycarbonate protection screen</li>
                  <li>
                    Includes M6 bolts, nyloc nuts, washers, cover caps and
                    handle gaskets
                  </li>
                  <li>Easy self-installation with fitting instructions</li>
                  <li>OEM look and finish</li>
                  <li>Compliant with UN/ECE Regulation No. 43</li>
                  <li>PCT Patent Pending application number: PCT/GB2024/051803</li>
                  <li>Other vehicle makes and models coming soon.</li>
                </ul>
              </Box>

              <div style={{ padding: "0px 10px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  className="buttonSend"
                  onClick={() => handleOpenModal()}
                >
                  Order Now
                </Button>
              </div>
            </Box>

            <Box className="customBox3" sx={{ position: "relative" }}>
              <Grid container>
                <img
                  src={[Frame1, Frame2, Frame3][imageIndex]}
                  width={"100%"}
                  alt={`frame${imageIndex + 1}`}
                />
              </Grid>
              <IconButton
                onClick={onClose}
                sx={{ position: "absolute", top: 12, right: 12 }}
              >
                <img src={closeImage} width={36} alt="close" />
              </IconButton>
              <IconButton
                onClick={handleNextImage}
                sx={{ position: "absolute", bottom: 10, left: 70 }}
              >
                <img src={RightArrow} width={40} alt="rightArrow" />
              </IconButton>
              <IconButton
                onClick={handlePrevImage}
                sx={{ position: "absolute", bottom: 10, left: 18 }}
              >
                <img src={LeftArrow} width={40} alt="LeftArrow" />
              </IconButton>
            </Box>
          </Grid>
        </Hidden>
        <Hidden lgUp>
          <Grid container className="modelContainerCustomset">
            <Box className="customBoxmobile" style={{ position: "relative" }}>
              <IconButton
                onClick={onClose}
                sx={{ position: "absolute", top: 20, right: 12 }}
              >
                <img src={closeImage} width={36} alt="close" />
              </IconButton>
              <IconButton
                onClick={handleNextImage}
                sx={{ position: "absolute", top: 20, left: 70 }}
              >
                <img src={RightArrow} width={40} alt="rightArrow" />
              </IconButton>
              <IconButton
                onClick={handlePrevImage}
                sx={{ position: "absolute", top: 20, left: 18 }}
              >
                <img src={LeftArrow} width={40} alt="LeftArrow" />
              </IconButton>
              <div className="scrollsetmobile" id="style-15">
                <Typography
                  variant="body1"
                  gutterBottom
                  className="subtitleModel2"
                >
                  Safety made easy with HandleBracks®{" "}
                  <img
                    src={ThatIs}
                    alt="thatIsSymbol"
                    width={18}
                    style={{
                      marginLeft: "4px",
                      paddingTop: "3px",
                      cursor: "pointer",
                    }}
                    onClick={handleTooltipToggle}
                  />
                </Typography>
                {tooltipOpen && (
                  <div
                    style={{
                      backgroundColor: "#fff",
                      padding: "10px",
                      borderRadius: "5px",
                      marginBottom: "10px",
                      position: "absolute",
                      border: "1px solid",
                      left: "60px",
                      right: "31px",
                    }}
                  >
                    {tooltipContent}
                  </div>
                )}
                <Typography
                  variant="h6"
                  component="h2"
                  gutterBottom
                  className="titleModel2"
                >
                  Ford Tourneo Custom
                </Typography>
                <Grid container>
                  <img
                    src={[Frame1, Frame2, Frame3][imageIndex]}
                    width={"100%"}
                    height={"300px"}
                    alt={`frame${imageIndex + 1}`}
                    style={{ padding: "0px 10px 20px 10px" }}
                  />
                </Grid>
                <Typography
                  variant="body1"
                  gutterBottom
                  className="subtitleModel70"
                >
                  Introducing the Ford Tourneo Custom 2012 - present Top Handle
                  1/2 Driver Protection Shield – a sleek solution crafted from
                  2mm steel HandleBracks® and 10mm polycarbonate. Simply unclip
                  existing bolt cover trims, refit longer bolts and install
                  within minutes. Designed for rear-facing seats, it offers easy
                  self-installation and an OEM look. Compliant with UN/ECE
                  Regulation No. 43, it ensures top-notch safety. PCT Patent Pending application number: PCT/GB2024/051803
                </Typography>

                <div style={{ padding: "0px 10px 10px 10px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="buttonSend"
                    onClick={() => handleOpenModal()}
                  >
                    Order Now
                  </Button>
                </div>
              </div>
            </Box>
          </Grid>
        </Hidden>
      </div>
    </Modal>
  );
};

export default CustomRoad;
