import React, { useState } from "react";
import Header from "../component/header";
import "../styles/style.css";
import Banner from "../component/banner";
import Product from "../component/product";
import Service from "../component/service";
import About from "../component/about";
import Footer from "../component/footer";
import OrderNowModal from "../component/model";
import CustomRoadModel from "../component/customRoad";

const LandingPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [opencustomModal, setOpencustomModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
    setOpencustomModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpencustomModal = () => {
    setOpencustomModal(true);
  };

  const handleClosecustomModal = () => {
    setOpencustomModal(false);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const offset = 40; // Adjust this value as needed
      const topPos =
        section.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({ top: topPos, behavior: "smooth" });
    }
  };

  return (
    <div id="TopContainer">
      <Header
        handleOpenModal={handleOpenModal}
        scrollToSection={scrollToSection}
      />
      <Banner scrollToSection={scrollToSection}/>
      <Product
        handleOpenModal={handleOpenModal}
        handleOpencustomModal={handleOpencustomModal}
      />
      <Service />
      <About handleOpenModal={handleOpenModal} />
      <OrderNowModal open={openModal} onClose={handleCloseModal} />
      <CustomRoadModel
        open={opencustomModal}
        onClose={handleClosecustomModal}
        handleOpenModal={handleOpenModal}
      />
      <Footer scrollToSection={scrollToSection} />
    </div>
  );
};

export default LandingPage;
