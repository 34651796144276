import React from "react";
import "../styles/style.css";
import { Grid, Button } from "@mui/material";

const About = (props) => {
  return (
    <div>
      <Grid container className="banner-content" id="aboutSection">
        <Grid
          item
          container
          className="aboutLetter"
        >
          <p className="partition" style={{ margin: "0px" }}>
            About
          </p>
        </Grid>
        <Grid item xs={12} lg={6} className="item20 mobile-order-1">
          <p className="feildtext2">
            As mental health providers, we're always prepared for potential
            violence, prioritizing safety for patients and staff through
            proactive risk mitigation.
          </p>

          <Button
            onClick={() => props.handleOpenModal()}
            style={{ float: "left" }}
            className="ordernow3"
          >
            ORDER NOW
          </Button>
        </Grid>
        <Grid item xs={12} lg={6} className="item21 mobile-order-2">
          <p className="feildtext4">
            Toughscreen Ltd manufacture and supply bespoke permanent rigid
            partition screens to safely protect individuals in commercial and
            industrial work. Originally founded from providing a bespoke
            solution to protecting mental health patients during healthcare
            transfers with understanding the needs and requirements daily
            challenges face with challenging behavior.
            <br />
            <br />
            We understand that important proactive measures need to be in place
            committed to ensuring the safety and well-being of your patients and
            dedicated staff with offering a design and build service specific to
            your requirements.
            <br />
            <br />
            An instant change of exhibiting or violent behaviour can be
            unexpected in any event with no pre-warning signs, resulting in
            severe consequences if not mitigated. Challenging behaviour may not
            always be down to mental illnesses thus planning ahead to minimise
            all potential risks safely by equipping with our screens with
            constant readiness for threatening behaviour and potential violence.
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default About;
