import "./App.css";
import React from "react";
import LandingPage from "./pages/landing";

function App() {
  React.useEffect(() => {
    document.title = "Toughscreen"; // Ensure this is correct
  }, []);

  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;
