import React from "react";
import "../styles/style.css";
import { Grid, Button } from "@mui/material";
import ArrowButton from "../assets/Images/buttonArrow.png";
import Logo from "../assets/Images/Logo_WordMark.svg";

const HeroBanner = (props) => {
  return (
    <div>
      <div className="banner">
        <Grid container className="banner-content">
          <Grid item container xs={12} lg={6} className="item1">
            <img src={Logo} alt="arrowbutton" className="logoheader" />
          </Grid>
          <Grid item  xs={12} lg={6} className="item2">
            <p className="feildtext">
              Experience unmatched safety and innovation with our cutting edge
              polycarbonate partitions, tailored for proactive protection in
              commercial and industrial spaces.
            </p>
          </Grid>
        </Grid>
        <Grid className="buttonposition">
          <Button disabled={true} className="arrayButton">
            <img src={ArrowButton} alt="arrowbutton" className="arrow" />
          </Button>
        </Grid>
      </div>
      <div>
        <Grid container className="banner-content3">
          <Grid item  xs={12} lg={6} className="item3 mobile-order-1">
            <p className="feildtext2">
              Explore our custom built polycarbonate partitions for commercial
              and industrial use, designed with safety in mind. Our origins in
              protecting mental health patients during transport to ensure
              proactive safety measures indoors, outdoors, and on the road.
            </p>

            <Button className="about_company" onClick={() => props.scrollToSection("aboutSection")}>ABOUT THE COMPANY</Button>
          </Grid>
          <Grid item  xs={12} lg={6}  className="item4 mobile-order-2">
            <p className="customfeild_text">
              Custom polycarbonate partitions, prioritising safety for mental
              health patients and beyond.
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default HeroBanner;
