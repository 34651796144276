import React, { useState } from "react";
import "../styles/style.css";
import { Grid } from "@mui/material";
import PersonOut from "../assets/Images/personout.png";
import Carout from "../assets/Images/carout.png";
import Minus from "../assets/Images/minus.png";
import Plus from "../assets/Images/plus.png";

const Service = () => {
  const [isIndustrialExpanded, setIsIndustrialExpanded] = useState(false);
  const [isCommercialExpanded, setIsCommercialExpanded] = useState(false);

  const toggleIndustrialExpand = () => {
    setIsIndustrialExpanded(!isIndustrialExpanded);
  };

  const toggleCommercialExpand = () => {
    setIsCommercialExpanded(!isCommercialExpanded);
  };

  return (
    <div className="sectionProduct" id="serviceSection">
      <Grid container className="banner-content">
        <Grid item xs={6} className="item3 dispayhideMobile"></Grid>
        <Grid item xs={12} lg={6} className="item4">
          <p className="customfeild_text2">
            Dedicated to providing the <br className="dispayhideMobile"/> safest solution to minimising any
            potential risk.
          </p>
        </Grid>
      </Grid>
      <Grid container className="banner-content6">
        <Grid item xs={12} lg={6} className="item9">
          <Grid>
            <div className="container_image">
              <img src={PersonOut} alt="Person" width={"100%"} />
            </div>
            <Grid container className="industial_cont">
              <Grid item xs={6} className="industrycontain">
                <p className="partition4">Industrial</p>
              </Grid>
              <Grid
                item
                xs={6}
                className="plus_minus_contain"
                onClick={toggleIndustrialExpand}
              >
                <img
                  src={isIndustrialExpanded ? Minus : Plus}
                  width={40}
                  height={40}
                  alt="minus"
                />
              </Grid>
            </Grid>
          </Grid>
          {isIndustrialExpanded && (
            <p className="feildtext9">
              From care homes to special needs schools, we specialise in
              designing practical yet visually engaging screens that blend
              seamlessly with their surroundings. Our bespoke solutions
              prioritise functionality without compromising on quality, ensuring
              a harmonious integration that maintains the desired ambiance. We
              cater to diverse environments, ensuring adaptability and
              satisfaction every step of the way.
            </p>
          )}
        </Grid>
        <Grid item xs={12} lg={6} className="item10">
          <Grid>
            <div className="container_image">
              <img src={Carout} alt="car" width={"100%"} />
            </div>
            <Grid container className="industial_cont">
              <Grid item xs={6} className="industrycontain">
                <p className="partition4">Commercial</p>
              </Grid>
              <Grid
                item
                xs={6}
                className="plus_minus_contain"
                onClick={toggleCommercialExpand}
              >
                <img
                  src={isCommercialExpanded ? Minus : Plus}
                  width={40}
                  height={40}
                  alt="plus"
                />
              </Grid>
            </Grid>
          </Grid>
          {isCommercialExpanded && (
            <p className="feildtext9">
              We offer customized partition screens tailored to the unique needs
              of our transport industry clients. Our screens are meticulously
              crafted using approved materials and designed to maximize space
              efficiency while maintaining OEM aesthetics.
              <br /> <br />
              Once installed, our screens ensure passenger comfort and do not
              hinder the operation of heating and ventilation systems.
              Transparent polycarbonate provides protection while allowing full
              visibility for drivers and passengers.
              <br /> <br />
              Considering fleet upgrades or new vehicles? Let us assess your
              needs to ensure compatibility and peace of mind. We prioritise
              safety and adhere to industry standards for interior projections,
              following DfT guidelines for taxi and PHV vehicle screens.
            </p>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Service;
