import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  InputLabel,
  IconButton,
  CircularProgress,
} from "@mui/material";
import "../styles/style.css";
import closeImage from "../assets/Images/close.png";
import axios from "axios";

const OrderNowModal = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    company: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false); // State for loader

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrors((prevDataError) => ({
      ...prevDataError,
      [id]: "",
    }));
  };

  const handleSend = () => {
    const newErrors = {};

    // Validate fields
    if (!formData.name.trim()) {
      newErrors.name = "Please enter your name";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Please enter your email";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email";
    }
    if (!formData.message.trim()) {
      newErrors.message = "Please enter your message";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true); // Start loader

    const url =
      "https://c1mu78xiti.execute-api.eu-north-1.amazonaws.com/dev/emailServiceResource";
    const data = {
      name: formData.name,
      email: formData.email,
      mobile: formData.contactNumber,
      company: formData.company,
      description: formData.message,
    };

    axios
      .post(url, data)
      .then((response) => {
        setFormData({
          name: "",
          email: "",
          contactNumber: "",
          company: "",
          message: "",
        });
        setErrors({});
        setShowSuccess(true);
        setLoading(false); // Stop loader
      })
      .catch((error) => {
        setLoading(false); // Stop loader
      });
  };

  const handleClose = () => {
    setShowSuccess(false);
    onClose();
  };

  return (
    <Modal open={open} className="model" aria-labelledby="order-now-modal">
      <Box className="customBox">
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 10, right: 10 }}
        >
          <img src={closeImage} width={18} alt="close" />
        </IconButton>
        {showSuccess ? (
          <Box className="successBox">
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              className="titleModel"
            >
              Your request has been sent
            </Typography>
            <Typography variant="body1" gutterBottom className="subtitleModel">
              Thank you for the request. We will contact you within 24-48 hours.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className="buttonSend"
              onClick={handleClose}
            >
              OKAY
            </Button>
          </Box>
        ) : (
          <>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              className="titleModel"
            >
              Get a consultation
            </Typography>
            <Typography variant="body1" gutterBottom className="subtitleModel">
              Book a consultation now for personalized safety solutions.
            </Typography>
            <div className="customscroll" id="style-15">
              <InputLabel htmlFor="name" className="textLabelmodel">
                Name
              </InputLabel>
              <TextField
                id="name"
                fullWidth
                variant="outlined"
                value={formData.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
                margin="dense"
                size="small"
                className="textfieldCustom"
                placeholder="Your name"
              />
              <InputLabel className="textLabelmodel" htmlFor="email">
                Email
              </InputLabel>
              <TextField
                id="email"
                fullWidth
                variant="outlined"
                type="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                margin="dense"
                size="small"
                className="textfieldCustom"
                placeholder="Enter your e-mail"
              />
              <InputLabel className="textLabelmodel" htmlFor="contactNumber">
                Contact Number (optional)
              </InputLabel>
              <TextField
                id="contactNumber"
                fullWidth
                variant="outlined"
                type="text" // Change to "text" to prevent 'e' input on some devices
                value={formData.contactNumber}
                onChange={handleChange}
                inputProps={{ maxLength: 20, inputMode: "numeric" }} // Limit to 20 digits and numeric input
                margin="dense"
                size="small"
                className="textfieldCustom"
                placeholder="Enter your contact number"
              />
              <InputLabel className="textLabelmodel" htmlFor="company">
                Company (optional)
              </InputLabel>
              <TextField
                id="company"
                fullWidth
                variant="outlined"
                value={formData.company}
                onChange={handleChange}
                margin="dense"
                size="small"
                className="textfieldCustom"
                placeholder="Enter your company"
              />
              <InputLabel className="textLabelmodel" htmlFor="message">
                Message
              </InputLabel>
              <TextField
                id="message"
                aria-label="minimum height"
                className="textfieldCustom"
                multiline
                rows={4}
                placeholder="Enter your message"
                style={{ width: "100%", marginTop: 10 }}
                value={formData.message}
                onChange={handleChange}
                error={!!errors.message}
                helperText={errors.message}
                margin="dense"
              />
              <div style={{ padding: "0px 10px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  className="buttonSend"
                  disabled={!formData.name.trim() || loading} 
                  onClick={handleSend}
                >
                  {loading ? (
                    <CircularProgress style={{ color: "#9a1d29" }} size={20} />
                  ) : (
                    "Send"
                  )}
                </Button>
              </div>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default OrderNowModal;
